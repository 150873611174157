import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  fitmentSearch: {
    ...shopifyEnterpriseGenericDefaults.fitmentSearch,
    categorySelectionPages: [
      ...shopifyEnterpriseGenericDefaults.fitmentSearch.categorySelectionPages,
      {
        pathname: window.document.querySelector('#cm-wheel-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
    ],
    categorySelectionPageRedirect: true,
  },
  product: {
    ...shopifyEnterpriseGenericDefaults.product,
    searchResultsImageWidth: 500,
    searchResultsImageHeight: 500,
  },
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets,
    {
      name: 'FacetWheelBrandTiles',
      type: 'FacetTiles',
      location: {
        selector: '#cm-wheel-brand-list',
        class: 'productgrid--outer',
      },
      template: 'facetTiles/hierarchicalContainerBrands',
    },
  ],
};
